import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import React, { useState, useEffect } from 'react';
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from 'react-toastify';
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import Login from "./views/Login/index.js";
import Admin from "./layouts/Admin.jsx";
import PrivateRoute from './store/PrivateRoute';

import { logout, setAddress, setNetwork, getNonce, login } from './store/actions/Auth';
import { connectMetamask, getNetworkSymbol} from "./store/walletConnect";
import { networkType } from "./store/config";
import { web3 } from "./store/web3";
import { tokenVerify } from "./store/jwtVerify";

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const hist = createBrowserHistory();

const App = (props) => {
let [login,setLogin] = useState(false);

const handleWalletChanges = (network) => {
    if(network){
        connectMetamask(network, networkType)
        .then(async ({address}) => {
          if(address && !login){
              let chain = await web3.eth.getChainId();
              chain = web3.utils.hexToNumber(chain);
              chain = Number(chain);
              props.setAddress({publicAddress:address,chain});
              props.getNonce({publicAddress:address,chain});
              setLogin(true);
            //   return EventBus.publish("success", `wallet connect successfuly`); 
          }
        })
        .catch((error) => {
          return EventBus.publish("error", `failed to connect wallet: ${error}`); 
      });
    }
};

const handleChainChange = async (chainId) => {
    const networkId = web3.utils.hexToNumber(chainId);
    const networkSymbol = await getNetworkSymbol(networkId.toString());
    if(!["eth","bnb","avax","xdc"].includes(networkSymbol)) return EventBus.publish("error", `Please select eth, bnb, xdc or avax`); 
    if(networkSymbol !== props.network) props.setNetwork(networkSymbol);
    handleWalletChanges();
};

const handleWalletChange = async (accounts) => {
    handleWalletChanges();
};

const loginWallet = async(nonce) => {
    try {
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      if(address){
        let chain = await web3.eth.getChainId();
        chain = web3.utils.hexToNumber(chain);
        chain = Number(chain);
        const signature = await web3.eth.personal.sign(`eplison marketplace signature ${nonce}`, address);
        if(address && signature) props.login({ address, signature, nonce, chain})
      }
    } catch (error) {
        return EventBus.publish("error", `failed to create signature: ${error}`); 
    }
}

useEffect(()=>{
    if(props.nonce && props.isChangeNetwork === true) loginWallet(props.nonce);
},[props.nonce])

useEffect(()=>{

  EventBus.on('info', (e) => toast.info(e));
  EventBus.on('error', (e) => toast.error(e));
  EventBus.on('success', (e) => toast.success(e));
  if(!tokenVerify()) EventBus.on("tokenExpired", () => props.logout());
  EventBus.on("tokenExpired", () => props.logout());

  if (typeof window.ethereum !== 'undefined') {
        // Register the event listener when the component mounts
        window.ethereum.on('chainChanged',()=>{
            setLogin(false)
            props.logout();
        });
        // Listen for accounts changed event
        window.ethereum.on('accountsChanged',()=>{
            setLogin(false)
            props.logout();
        });
  }
},[]);

useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      if (props.isChangeNetwork === true) {
        handleWalletChanges(props.network);
        setLogin(false);
      } 
    }
  }, [props.isChangeNetwork]);
  // render() {
    return (
      <div>
        <ToastContainer />
        <Router history={hist}>
          <Switch>
            {!props.isLogin 
            ?  
              <>
                <Route path="/login" render={props => <Login {...props} />} />
                <Redirect from="/" to="/login" />
              </>
            : 
              <>
                <PrivateRoute path="/home" component={props => <Admin {...props} />} />
                <Redirect from="/" to="/home" />
              </>
            }
          </Switch>
        </Router>
      </div>
    )
  // }
}
const mapDispatchToProps = { logout, setAddress, setAddress, setNetwork,getNonce,};

const mapStateToProps = ({ Auth }) => {
  let { auth, isChangeNetwork,isLogin } = Auth
  return { auth, isChangeNetwork,isLogin }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
