import Web3 from 'web3';
import { networkId } from './config';
export const connectMetamask = async (network, isTestnet) => {
    try {
      // Check if Metamask is installed
      if (typeof window.ethereum !== 'undefined') {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.enable();

        let x = await web3.currentProvider.chainId;
        if (!x) {
            return Promise.reject(new Error({message:`Please Install Metamask!!!`}));
        }

        // Define network details
        let rpcUrl, chainId, chainName, nativeCurrency, blockExplorerUrl;
        switch (network) {
          case 'hedera':
            rpcUrl = isTestnet
              ? ['https://testnet.hashio.io/api']
              : ['https://mainnet.hashio.io/api']
            chainId = isTestnet ? '0x128' : '0x127';
            chainName = isTestnet ? 'Hedera Testnet' : 'Hedera Mainnet';
            nativeCurrency = {
              name:  isTestnet ? 'Hedera Testnet' : 'Hedera Mainnet',
              symbol: isTestnet ? 'HBAR' : 'HBAR',
              decimals: 18,
            };
            blockExplorerUrl = isTestnet
              ? 'https://hashscan.io/testnet'
              : 'https://hashscan.io/mainnet';
            break;
          default:
            return Promise.reject(new Error({message:'Invalid network'}));
        }
            const netId = await web3.eth.net.getId();
            if (isTestnet && netId !== 296) {
                await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                    chainId: chainId,
                    chainName: chainName,
                    nativeCurrency: nativeCurrency,
                    rpcUrls: rpcUrl,
                    blockExplorerUrls: [blockExplorerUrl],
                    },
                ],
                });
            }else if(!isTestnet && netId !== 295){
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                    chainId: chainId,
                    chainName: chainName,
                    nativeCurrency: nativeCurrency,
                    rpcUrls: rpcUrl,
                    blockExplorerUrls: [blockExplorerUrl],
                    },
                ],
                });
            }
            else {
                // Switch to the selected network
                // await web3.eth.switchNetwork(chainId);
                await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId }],
                });
            }

            const accounts = await web3.eth.getAccounts();
            const address = accounts[0];
            return Promise.resolve({address});
       
      } else {
        console.log('Metamask is not installed');
        return Promise.reject(new Error({message:'Metamask is not installed'}));
      }
    } catch (error) {
      console.log('Error connecting to Metamask:', error);
      return Promise.reject(error.message);
    }
};

export const getNetworkSymbol = (networkId) => {
    const networkMap = {
      '295': 'hedera', // Hedera Mainnet
      '296': 'hedera', // Hedera Testnet
    };
  
    return networkMap[networkId] || 'Unknown';
};
  