import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams, Link, withRouter } from "react-router-dom";
import {Tabs, Tab, Form, Modal, Button} from 'react-bootstrap';
import { connect } from "react-redux";
import moment from 'moment';
import Countdown,{zeroPad} from 'react-countdown';
import EventBus from 'eventing-bus';
import {makeTokens,web3} from "../../store/contract"
import { networkType } from "../../store/config";
import {getSingleNft, getWhitelistToken, setLoader, listNft,unListNft, transferNft, createAuction, getBids, placeBid, endAuction} from "../../store/actions/Auth"


const WhiteList= (props) => {
    
    useEffect(()=>{
        props.getWhitelistToken()
    },[]);

    return(
        <div className='whitelist'>  
            <h1>WhiteList</h1>

            <div className='table-wrap'>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Wallet Address</th>
                        </tr>
                    </thead>

                    <tbody>
                        {props.whitelistToken && props.whitelistToken.length > 0 && props.whitelistToken.map(item=>
                        <tr>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>
                            <td>{item.publicAddress}</td>
                        </tr>
                        )}

                    </tbody>
                </table>
            </div>
        </div>
    );
};

const mapDispatchToProps = {  setLoader, getWhitelistToken};

const mapStateToProps = ({ Auth }) => {
  let { isLogin, singleNft,publicAddress, whitelistToken} = Auth;
  return { isLogin, singleNft,publicAddress, whitelistToken}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WhiteList));