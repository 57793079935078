import React, { useEffect, useState } from 'react';
import { NavLink, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import EventBus from 'eventing-bus';
import moment from 'moment';
import { Form, Button } from 'react-bootstrap';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { makeTokens, web3 } from "../../store/contract";
import { setLoader, getCollection, getUser, getCollectionDetails, addRemoveFavItems } from "../../store/actions/Auth";

import "./index.css";

const alternate = "/images/alternate.jpg";
const collectionImage = "https://ox-burn-assets.s3.us-east-2.amazonaws.com/collection-img.png"

const facebook = "/images/facebook-black.png";
const twitter = "/images/twitter-black.png";
const google = "/images/google-black.png";
const chatBlack = "/images/chat-black.png";
const telegram = "/images/telegram.png";
const discord = "/images/discord.png";
const medium = "/images/medium.png";
const instagram = "/images/instagram.png";
const website = "/images/website.png";
const like = "/images/likes.svg";
const back = "/images/arrow-left.png";


const Collection = (props) => {
    
    let { tokenAddress, chain } = props.match.params;
    let [maxSupply, setMaxSupply] = useState(0);
    let [totalMint, setTotalMint] = useState(0);
    let [price, setPrice] = useState(0);
    let [order, setOrder] = useState("asc");
    let [filter, setFilter] = useState("");
    let [handleChange, setHandleChange] = useState("");
    let [collection, setCollection] = useState();
    let [nfts, setNfts] = useState([]);

    useEffect(() => {
        props.setLoader({status:true,message:"Load collection"});
        props.getCollectionDetails({ tokenAddress, chain });
    }, [props.chain])

    const makeContract = async (contractAddress, type) => {
        let { ERC721ABI, ERC1155ABI } = await makeTokens();
        let Token = new web3.eth.Contract(type == "erc721" ? ERC721ABI : ERC1155ABI, contractAddress);
        if (type == "erc721") {
            let totalMint = await Token.methods.totalSupply().call();
            let maxSupply = await Token.methods.maxSupply().call();
            setMaxSupply(maxSupply);
            setTotalMint(totalMint);
            setPrice(0);
        } else if (type == "erc1155") {
            let totalMint = await Token.methods.totalSupply(1).call();
            let maxSupply = await Token.methods.maxSupply().call();
            setMaxSupply(maxSupply);
            setTotalMint(totalMint);
            setPrice(0);
        }
    }

    useEffect(() => {
        if (Object.keys(props.singleCollectionDetails).length > 0) {
            let { collectionDetail, nfts } = props.singleCollectionDetails;
            setCollection(collectionDetail);
            if (collectionDetail.tokenType == "erc1155" && nfts.length > 0) {
                nfts = nfts.filter(item => item['mintAmount'] !== 0)
                setNfts(nfts);
            } else {
                setNfts(nfts);
            }
            makeContract(collectionDetail.tokenAddress, collectionDetail.tokenType);
        }
    }, [props.singleCollectionDetails,props.chain])

    const handleImageError = (idx) => {
        nfts[idx]['image'] = alternate;
        setNfts([...nfts]);
    };

    const handleUserImageError = (idx) => {
        nfts[idx]['users']['image'] = alternate;
        setNfts([...nfts]);
    };

    const AddFavItems = (nftId) => {
        props.addRemoveFavItems({ nftId, tokenAddress, chain });
    }

    const filterNft = async (e) => {
        e.preventDefault();
        setFilter(handleChange);
    }

    return (
      
    <>
    <section className="profile">
        <h1><Link to="/home"><img src="./images/back-btn.png" alt="..." /></Link>MY COLLECTIONS</h1>
        
        <div className='inner-wrap'>
            <div className="inner">
                <div className="img-wrap">
                    <img src={collection && collection['profileImage'] ? collection['profileImage'] : collectionImage} alt="" />
                </div>

                <div className="content">
                    <div className="upper">
                        <div >
                            <h3>{collection && collection['collectionName'] ? collection['collectionName'] : ""}</h3>

                            <p>{collection && collection['collectionDesc'] ? collection['collectionDesc'] : ""}</p>
                        </div>

                        <ul>
                        <li>
                                                {collection && collection['website'] && collection['website'] !== "undefined"  &&
                                                    <a href={collection['website']}>
                                                        <img src={website} alt="" />
                                                    </a>
                                                }
                                                </li>

                                                <li>

                                                {collection && collection['discord'] && collection['discord'] !== "undefined" &&
                                                    <a href={collection['discord']}>
                                                        <img src={discord} alt="" />
                                                    </a>
                                                }

                                                </li>

                                                <li>

                                                {collection && collection['instagram'] && collection['instagram'] !== "undefined" &&
                                                    <a href={collection['instagram']}>
                                                        <img src={instagram} alt="" />
                                                    </a>
                                                }

                                                </li>

                                                <li>

                                                {collection && collection['medium'] && collection['medium'] !== "undefined" &&
                                                    <a href={collection['medium']}>
                                                        <img src={medium} alt="" />
                                                    </a>
                                                }

                                                </li>
                                                <li>

                                                {collection && collection['telegram'] && collection['telegram'] !== "undefined" &&
                                                    <a href={collection['telegram']}>
                                                        <img src={telegram} alt="" />
                                                    </a>
                                                }
                                                </li>
                        </ul>
                        
                    </div>

                    <div className="bottom">
                        <Link className="address" to={`/home/mintcollection/${chain}/${tokenAddress}`}>
                                    <span></span>
                                    Minting Now
                        </Link>

                        {/* <a className="common-btn" href="">Edit Collection <img src="./images/follow.png" alt="" /></a> */}
                         {/* {collection && collection.users.publicAddress == props.publicAddress.toLowerCase() && */}
                                                <NavLink to={`/home/editcollection/${chain}/${tokenAddress}`} className="common-btn">
                                                    Edit Collection
                                                </NavLink>
                                            {/* }    */}
                    </div>
                </div>
            </div>

            <div className='bottom-info'>
                    <div className='info'>
                        <span>Total Mint</span>
                        <label>{totalMint}</label>
                    </div>

                    <div className='info'>
                        <span>Max Supply</span>
                        <label>{maxSupply}</label>
                    </div>

                    <div className='info'>
                        <span>Created</span>
                        <label>{collection && collection['createdAt'] ? moment(collection['createdAt']).format("MMMM-DD-YYYY") : "-"}</label>
                    </div>

                    <div className='info'>
                        <span>Creator Earnings </span>
                        <label>{(Number(price) * Number(totalMint)).toFixed(4)}</label>
                    </div>

                    <div className='info'>
                        <span>Chain</span>
                        <label>HBAR</label>
                    </div>

                    <div className='info'>
                        <span>Category</span>
                        <label>{collection && collection['collectionType'] ? collection['collectionType'] : "-"}</label>
                    </div>
            </div>
        </div>

    </section>

    <section className="explore">

                        <div className="filters-wrap">
                            <div className="form-group search-wrap">
                                <input type="search" placeholder='Enter your word art' onChange={e => setHandleChange(e.target.value)} />
                                <button onClick={filterNft}><img src={"https://ox-burn-assets.s3.us-east-2.amazonaws.com/search.png"} alt="" /></button>
                            </div>

                            {/* <div className="form-group"> */}
                                <select onChange={e => setOrder(e.target.value)} value={order}>
                                    <option value={"asc"}>Asc</option>
                                    <option value={"desc"}>Desc</option>
                                </select>
                            {/* </div> */}
                        </div>

            <div className="container">
                <div className="row">
                {nfts.length > 0 && nfts.filter((a) => a.title && a.title.toString().includes(filter)).sort((a, b) => order == "asc" ? a.nftId - b.nftId : b.nftId - a.nftId).map((item, idx) =>
                    <div className="col-md-6 col-lg-4">
                        {/* <Link to="/home/editcollection" className="card-box"> */}
                        <Link to={`/home/itemdetail/${item['_id']}`} className="card-box">
                            <div className="card-inner">
                            <img key={idx} src={item && item['image'] ? item && item['image'] : alternate} onError={() => handleImageError(idx)} alt="Alternate Image" />
                            <div className="reaction">
                                <span>
                                {/* <img src={bagWhite} alt="" /> */}
                                Detail
                                </span>
                            </div>

                            {/* <div className="comming-soon">
                                COMING SOON
                            </div> */}

                            <h3>{item['title'] ? item['title'] : "-"}</h3>
                                <div class="card-body">
                                    <div className="card-left">
                                        {/* <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/avatar.png" alt="" /> */}
                                        <div className="card-right-text">
                                            <span>Token Id</span>
                                            <h4>{item && item.nftId}</h4>
                                        </div>
                                    </div>
                                    <div className="card-right">
                                        <span>HBAR</span>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )}
                </div>
            </div>
    </section>
    </>
    //https://ox-burn-assets.s3.us-east-2.amazonaws.com/search.png
    );
}

const mapDispatchToProps = { setLoader, getCollection, getUser, getCollectionDetails, addRemoveFavItems };

const mapStateToProps = ({ Auth }) => {
    let { isLogin, chain, publicAddress, singleCollectionDetails } = Auth;
    return { isLogin, chain, publicAddress, singleCollectionDetails }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Collection));