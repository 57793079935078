import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {getWhitelistSeeds, setLoader} from "../../store/actions/Auth"


const WhiteList= (props) => {
    
    useEffect(()=>{
        props.getWhitelistSeeds()
    },[]);

    return(
        <div className='whitelist'>  
            <h1>WhiteList Seed Phase</h1>

            <div className='table-wrap'>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Wallet Address</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                        </tr>
                    </thead>

                    <tbody>
                        {props.setWhiteListSeeds && props.setWhiteListSeeds.length > 0 && props.setWhiteListSeeds.map((item,index)=>
                        <tr>
                            <td>{index+1}</td>
                            <td>{item.publicAddress ? item.publicAddress  : "-"}</td>
                            <td>{item.name ? item.name  : "-"}</td>
                            <td>{item.email ? item.email  : "-"}</td>
                            <td>{item.phone ? item.phone  : "-"}</td>
                        </tr>
                        )}

                    </tbody>
                </table>
            </div>
        </div>
    );
};

const mapDispatchToProps = {  setLoader, getWhitelistSeeds};

const mapStateToProps = ({ Auth }) => {
  let { isLogin, setWhiteListSeeds} = Auth;
  return { isLogin, setWhiteListSeeds}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WhiteList));