import React, { useEffect, useState } from 'react';
import { NavLink, Link, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import EventBus from 'eventing-bus';
import moment from 'moment';
import { Form, Button } from 'react-bootstrap';
import { setLoader, getAllCollection, getExplore, addRemoveFavItems } from "../../store/actions/Auth";

import './index.css';
const collectionImage = "/images/collection-img.png";
const alternate = "/images/alternate.jpg";
const bag = "/images/bag.png";
const bagWhite = "/images/bag-white.png";
const like = "/images/likes.svg";

const Drops = (props) => {

    const [status, setStatus] = useState();
    const [itemPerRow, setItemPerRow] = useState(10);
    const [next, setNext] = useState(itemPerRow);
    const [setFound, setNotFound] = useState(false);

    const [items, setItems] = useState([]);

    useEffect(() => {
        props.setLoader({message:"Load NFT's...",status:true});
        props.getExplore({ chain: props.chain });
    }, [props.chain]);

    useEffect(() => {
        if (props.explore.length > 0) setItems(props.explore);
    }, [props.explore]);

    const handleMoreImage = () => {
        setNext(next + itemPerRow);
    };

    const handleImageError = (idx) => {
        items[idx]['image'] = alternate;
        setItems([...items]);
    };

    const handleUserImageError = (idx) => {
        items[idx]['users']['image'] = alternate;
        setItems([...items]);
    };

    return (

        <section className="explore">
        <div className="container">
            <div className="head">
                <h1>Minted NFT's</h1>
            </div>
            <div className="row">
            {
             !setFound ?
                items && items.length > 0 && items.slice(0, next).map((item, idx) =>
                <div className="col-md-6 col-lg-4">
                     <Link to={`/home/itemdetail/${item['_id']}`} className="card-box">
                        <div className="card-inner">
                        {/* <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/collection-img.png" alt="..." /> */}
                        <img key={idx} src={ item && item['image'] ? item['image'] : alternate} onError={() => handleImageError(idx)} alt="Alternate Image" />

                        {/* <div className="reaction">
                            <span>
                            <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/reaction.png" alt="" />
                            100
                            </span>
                        </div> */}
                          <Link to={`/home/itemdetail/${item['_id']}`} className="reaction">
                                                                {/* <img src={bag} alt="" />
                                                                <img className="white" src={bagWhite} alt="" /> */}
                                                                Detail
                             </Link>

                        {/* <div className="minting">
                            MINTING
                        </div> */}

                            <h3>{item && item['title'] ? item['title'] : "-"}</h3>
                            <div class="card-body">
                                <div className="card-left">
                                    {/* <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/avatar.png" alt="" /> */}
                                    <div className="card-right-text">
                                        <span>Token Id </span>
                                        <h4>{item && item.nftId}</h4>
                                    </div>
                                </div>
                                <div className="price">
                                    <label>price</label>
                                    <span>{item && item.price} HBAR</span>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>) 
            :
            <div className="text-center">
                <h2> Not Found </h2>
            </div>
           }
        </div>
        {next < items.length && (
                        <a className="common-btn border-white" onClick={handleMoreImage}>
                            Load More
                        </a>
                        )}
        </div>
    </section>
    );
}

const mapDispatchToProps = { setLoader, getAllCollection, getExplore, addRemoveFavItems };

const mapStateToProps = ({ Auth }) => {
    let { isLogin, chain, publicAddress, singleCollectionDetails, collections, explore } = Auth;
    return { isLogin, chain, publicAddress, singleCollectionDetails, collections, explore }
}

export default connect(mapStateToProps, mapDispatchToProps)(Drops);