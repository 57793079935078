import React, { useEffect, useState } from 'react';
import { NavLink, Link, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import EventBus from 'eventing-bus';
import { setLoader, getAllNfts, getAuctions, addRemoveFavItems, getAllCollection } from "../../store/actions/Auth";
import "./index.css";

const collectionImage = "/images/collection-img.png";
const bag = "/images/bag.png";
const bagWhite = "/images/bag-white.png";
const like = "/images/likes.svg";
const alternate = "/images/alternate.jpg";

const MyCollection = (props) => {
    const [collections, setCollections] = useState([]);
    const [itemPerRow, setItemPerRow] = useState(10);
    const [next, setNext] = useState(itemPerRow);

    useEffect(() => {
        props.getAllCollection({chain: props.chain});
    }, [props.chain]);

    useEffect(()=>{
        if(props.collections.length > 0) setCollections(props.collections);
    },[props.collections])

    const handleCollectionImageError = (idx) => {
        collections[idx]['profileImage'] = alternate;
        setCollections([...collections]);
    }

    const handleCollectionUserImageError = (idx) => {
        collections[idx]['users']['image'] = alternate;
        setCollections([...collections]);
    }

    const handleMoreImage = () => {
        setNext(next + itemPerRow);
    };


    return (
        <section className="explore">
            <div className="container">
                <div className="head">
                    <h1>MY COLLECTION</h1>
                </div>
                {collections.length > 0 &&
                <div className="row">
                    {collections.slice(0, next).map((item, idx)=>
                    <div className="col-md-6 col-lg-4">
                        <Link to={`/home/collection/${item['chain']}/${item['tokenAddress']}`} className="card-box">
                        {/* <Link to="/home/singlecollection" className="card-box"> */}
                            <div className="card-inner">
                            {/* <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/collection-img.png" alt="..." /> */}
                            <img key={idx}  src={item['profileImage'] ? item['profileImage'] : alternate } onError={() => handleCollectionImageError(idx)} />
                            {/* <div className="reaction">
                                <span>
                                <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/reaction.png" alt="" />
                                100
                                </span>
                            </div> */}

                            {/* <div className="comming-soon">
                                COMING SOON
                            </div> */}

                            {/* <h3>"Hamlet Contemplates Yorick's</h3> */}
                            <h3>{item['collectionName'] ? item['collectionName'] : ""}</h3>
                                <div class="card-body">
                                    {/* <div className="card-left">
                                        <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/avatar.png" alt="" />
                                        <div className="card-right-text">
                                            <span>Creator</span>
                                            <h4>SalvadorDali</h4>
                                        </div>
                                    </div> */}
                                    <div className="card-right">
                                        <span>HBAR</span>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    )}  
                        <div className="mt-4 text-center">
                                 {next < collections.length &&
                                        <a className="common-btn border-white" onClick={handleMoreImage}>
                                            Load More
                                        </a>
                                 }
                        </div>
                    
                    {/* // <div className="col-md-6 col-lg-4">
                    //     <Link to="/home/singlecollection" className="card-box">
                    //         <div className="card-inner">
                    //         <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/collection-img.png" alt="..." />

                    //         <h3>"Hamlet Contemplates Yorick's</h3>
                    //             <div class="card-body">
                    //                 <div className="card-left">
                    //                     <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/avatar.png" alt="" />
                    //                     <div className="card-right-text">
                    //                         <span>Creator</span>
                    //                         <h4>SalvadorDali</h4>
                    //                     </div>
                    //                 </div>
                    //                 <div className="card-right">
                    //                     <span>HBAR</span>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     </Link>
                    // </div> */}

                </div>
                }
            </div>
        </section>
    );
}

const mapDispatchToProps = { setLoader, getAllNfts, getAuctions, addRemoveFavItems, getAllCollection };

const mapStateToProps = ({ Auth }) => {
    let { isLogin, chain, publicAddress, setAuctions, setAllNfts, collections } = Auth;
    return { isLogin, chain, publicAddress, setAuctions, setAllNfts, collections }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyCollection);
