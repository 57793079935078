let { web3 } = require('../web3');
let { env } = require('../config');

let TokenData = require(`./${env}/0xBurnToken.json`);
let ERC721 = require(`./${env}/ERC721.json`);
let Marketplace = require(`./${env}/marketplaceContract.json`);
let TokenABI = "", TokenAddress = "", Token = "", ERC721ABI = "", ERC721BYTECODE = "", MarketplaceAddress = "", MarketplaceAbi = "", MarketplaceToken = "";
let makeTokens =  async () => {
  return new Promise(async (resolve, reject)=>{
    let chain = await web3.eth.getChainId();
    chain = web3.utils.hexToNumber(chain);
    chain = Number(chain);
    if(chain == 296 || chain == 295) {
      /********* Marketplace Token *********/
      TokenAddress = TokenData['address']
      TokenABI = TokenData['abi'];
      Token = new web3.eth.Contract(TokenABI,TokenAddress);
      /********* ERC721 *********/
      ERC721ABI = ERC721['abi'];
      ERC721BYTECODE = ERC721['bytecode'];
      /********* Marketplace ********/
      MarketplaceAddress = Marketplace['address']
      MarketplaceAbi = Marketplace['abi']
      MarketplaceToken = new web3.eth.Contract(MarketplaceAbi,MarketplaceAddress);
      
      return resolve({TokenAddress,TokenABI,Token,ERC721ABI,ERC721BYTECODE,MarketplaceAddress,MarketplaceAbi,MarketplaceToken})
    }
  })
}

export {
  web3,
  makeTokens,
};
